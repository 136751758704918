import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          I grew up on the water taking sailing lessons each summer off the coast of Massachusetts and have always had an appreciation for the ocean and its vast ecosystems. Through sailing, I learned about different types of clouds, why the wind can shift and change, and how to understand weather patterns. My time on the water also reinforced my goal to pursue a degree in environmental science. Through my career, I plan to use existing environmental research to help develop and implement sustainable practices and policies to reduce greenhouse gas emissions and mitigate the impacts of climate change. Additionally, I also plan to conduct new research to improve our understanding of the causes and effects of climate change and develop new technologies to combat it.
 </p>
 <p>
 During the 2020-2021 academic year, I took a gap year in order to participate in several environmental programs. For the fall, I participated in my SEA Semester program, in which I joined 23 other students, along with 12 crew members, and lived aboard a tall ship, which was a life-changing experience. We conducted environmental and oceanographic research while also learning to sail the ship. Each day, we would collect data for individual student research projects, as well as for the research conducted by the SEA Semester and Woods Hole Oceanographic Institute. For my individual research project, I compared the water quality between coastal and offshore water to understand more about why water quality differs. To collect water and data samples, I used deployments including a carousel and free CTD, and harvested and counted bacteria colonies. Studying my samples and also contributing to other students' research helped me to see how much I enjoy scientific research and showed me that I want to contribute to research efforts throughout my career.
 </p><p>
 During the spring and summer of my gap year, I volunteered at three different organizations to learn about how they each focus on environmental issues. At the Lloyd Center for the Environment in South Dartmouth, Massachusetts, as an Education Intern, I taught students between the ages of five and ten years old about how environmental concepts  relate to their daily lives to encourage them to live in sustainable ways and have an interest in protecting the Earth. Some of the main topics I taught were biodiversity loss, invasive species, and how art can be made from nature.  Of course the students loved digging and exploring for worms and other creatures under rocks! I also interned with my local state representative, Kathy LaNatra, as a Legislative Intern. Through the process of reading through legislation and learning about how bills get passed, I gained a much deeper understanding of how environmental issues can be changed both locally and globally. My third volunteer role, with the Massachusetts Chapter of the Sierra Club, was to help create a climate action database that highlights the sustainable efforts that each town in Massachusetts completed. Massachusetts leaders can reference this database to make their towns more sustainable. From these various experiences in the environmental field, I am excited to continue to learn more about how I can best contribute to environmental topics in the future.
 </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
