import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Environmental Science Major at American University",
          "SEA Semester Graduate",
          "NOAA Hollings Scholar",
          "Future Environmental Researcher and Scientist",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 30,
      }}
    />
  );
}

export default Type;
