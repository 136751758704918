import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/aileen1.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}><span className="headcolor">
              About Me
            </span></h1>
            <p className="home-about-body">
            <h1>
            <span className="purple">“Copepod… copepod… copepod!”</span></h1>
              <br /> I stated as I searched for organisms under the microscope to complete a one hundred count in the lab aboard the SSV Corwith Cramer during my Sea Education Association Semester (SEA Semester). Before beginning my six weeks at sea during the fall of 2020, I had never heard of a copepod and, after seeing several different types on the ship, I became fascinated by these creatures and all of the organisms that live throughout the ocean. Learning about copepods and many other oceanographic and environmental topics during my SEA Semester influenced my deep love for environmental science and drove me toward my passion for scientific research.
              <br />
              <br />
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <figure><img src={myImg} className="img-fluid" alt="avatar"/></figure>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Find me on</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/aileen-mcdonald-4516401a5"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/aileen.mcdonald/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
